import { KeyboardEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { IConversation, IMessage, Loader, SvgIcon } from '@ascd/witsby-components';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, IconButton } from '@mui/material';
import { cloneDeep, get, trim } from 'lodash';
import { useDropzone } from 'react-dropzone';
import { AppContext, SocketContext } from '@contexts';
import { ChatContext, eChatActionType } from '@contexts/chatContext';
import GET_CONVERSATIONS_FROM_USER_ID from '@graphql/schema/getConversationsFromUserId.graphql';
import GET_MESSAGES_FROM_CONVERSATION from '@graphql/schema/getMessagesFromConversation.graphql';
import CREATE_MESSAGE from '@graphql/schema/mutations/createMessage.graphql';
import GET_NEW_IMAGE_UPLOAD_URL from '@graphql/schema/newImageUploadUrl.graphql';
import {
  showToast,
  uploadFile,
  getQuillContent,
  handleGraphqlError,
  commonConversationsFilter,
} from '@utils';
import { ChatQuill } from '../ChatQuill';

interface IAttachmentType {
  id: string;
  title: string;
  fileType: string;
  imageKey: string;
  url: string;
  thumbnail: string;
}

const SendMessage = ({ onSend = () => null }: { onSend?: () => void }): JSX.Element => {
  const apolloClient = useApolloClient();
  const { socket } = useContext(SocketContext);
  const {
    state: { detailsPage, draftMessages },
    dispatch,
  } = useContext(ChatContext);
  const {
    state: { currentUser },
  } = useContext(AppContext);

  const sendButtonRef = useRef<HTMLButtonElement>(null);

  const [isTyping, setIsTyping] = useState(false);
  const [isUploadLoading, setIsUploadingLoading] = useState(false);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const conversation: IConversation = get(
    detailsPage,
    'detailsPageData.conversation',
    {},
  ) as IConversation;

  const sendTypingStatusStop = useCallback(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      if (socket) socket.emit('onTypingStop', { conversationId: conversation?._id });
      setIsTyping(false);
    }, 2000);
  }, [conversation?._id, socket]);

  const sendTypingStatus = useCallback(() => {
    if (isTyping) {
      sendTypingStatusStop();
    } else {
      setIsTyping(true);
      if (socket) socket.emit('onTypingStart', { conversationId: conversation?._id });
      sendTypingStatusStop(); // Ensure `onTypingStop` is called after the debounce delay
    }
  }, [conversation?._id, isTyping, sendTypingStatusStop, socket]);

  useEffect(
    () => () => {
      if (timerRef.current) clearTimeout(timerRef.current);
      sendTypingStatusStop();
    },
    [sendTypingStatusStop],
  );

  const handleInputChange = (value: string) => {
    if (conversation?._id) {
      sendTypingStatus();
      dispatch({
        data: {
          ...draftMessages,
          [conversation?._id]: {
            ...draftMessages[conversation?._id],
            message: value,
          },
        },
        type: 'DRAFT_MESSAGES' as eChatActionType.DRAFT_MESSAGES,
      });
    }
  };

  const handleMessageCache = useCallback(
    ({ createMessage, isError = false }: { createMessage: IMessage; isError?: boolean }) => {
      if (conversation?._id) {
        dispatch({
          data: {
            ...draftMessages,
            [conversation?._id]: {
              attachment: undefined,
              message: '',
            },
          },
          type: 'DRAFT_MESSAGES' as eChatActionType.DRAFT_MESSAGES,
        });
      }
      const { conversationId } = createMessage;

      // Check if the new message belongs to the current conversation
      if (conversation?._id !== conversationId && !isError) {
        // Read the cached conversation data for the current user
        const cachedAllConversationsDataList = apolloClient.readQuery({
          query: GET_CONVERSATIONS_FROM_USER_ID,
          ...commonConversationsFilter(currentUser.oktaId, conversation.type, false),
        });

        // Deep clone the unread counts to avoid direct mutation
        const cloneUnreadCountByConversations = cloneDeep(
          cachedAllConversationsDataList?.getConversationsFromUserId?.unreadCountByConversations ||
            {},
        );

        // Increment the unread count for the conversation
        cloneUnreadCountByConversations[`${conversationId}`] =
          (cloneUnreadCountByConversations[`${conversationId}`] || 0) + 1;

        // Write the updated unread counts back to the cache
        apolloClient.writeQuery({
          query: GET_CONVERSATIONS_FROM_USER_ID,
          ...commonConversationsFilter(currentUser.oktaId, conversation.type, false),
          data: {
            ...cachedAllConversationsDataList,
            getConversationsFromUserId: {
              ...(cachedAllConversationsDataList?.getConversationsFromUserId || {}),
              unreadCountByConversations: cloneUnreadCountByConversations,
            },
          },
        });

        return;
      }

      // Read the cached messages for the current conversation
      const cachedMessages = apolloClient.readQuery({
        query: GET_MESSAGES_FROM_CONVERSATION,
        variables: {
          conversationId,
          filter: {
            limit: 100,
            page: 0,
          },
        },
      });

      // Deep clone the cached messages to avoid direct mutation
      const cloneCachedMessages: IMessage[] = cloneDeep(
        cachedMessages?.getMessagesFromConversation?.messages || [],
      ).filter((m: IMessage) => {
        if (isError) return true;
        return m.sentAt !== createMessage.sentAt;
      });

      if (!isError) {
        // Add the new message to the cloned messages array
        cloneCachedMessages.push(createMessage);
      } else {
        const index = cloneCachedMessages.findIndex(
          (m) => m._id?.startsWith('SENDING') && createMessage.sentAt === m.sentAt,
        );
        if (index > -1) {
          cloneCachedMessages[`${index}`]._id = `ERROR_${createMessage.sentAt}`;
        }
      }

      // Write the updated messages back to the cache
      apolloClient.writeQuery({
        query: GET_MESSAGES_FROM_CONVERSATION,
        variables: {
          conversationId,
          filter: {
            limit: 100,
            page: 0,
          },
        },
        data: {
          ...cachedMessages,
          getMessagesFromConversation: {
            ...(cachedMessages?.getMessagesFromConversation || {}),
            messages: cloneCachedMessages,
          },
        },
      });
    },
    [
      apolloClient,
      conversation?._id,
      conversation.type,
      currentUser.oktaId,
      dispatch,
      draftMessages,
    ],
  );

  const [createMessage] = useMutation(CREATE_MESSAGE, {
    onError(error, clientOptions) {
      setIsUploadingLoading(false);
      handleGraphqlError(error);
      const createMessageInput = get(clientOptions, 'variables.createMessageInput');
      if (createMessageInput) {
        handleMessageCache({ createMessage: createMessageInput, isError: true });
      }
    },

    onCompleted(data) {
      handleMessageCache(data);
      onSend();
    },
  });

  const generateUrlAndFileUpload = async (file: File) => {
    const response = await apolloClient.query({
      query: GET_NEW_IMAGE_UPLOAD_URL,
      variables: { imageName: file.name },
      fetchPolicy: 'network-only',
    });
    const { key, url } = response.data.newImageUploadUrl;

    await uploadFile(url, file);
    return key;
  };

  const handleSendMessage = async () => {
    if (!conversation?._id || !draftMessages[conversation?._id]) {
      return;
    }

    const content = trim(draftMessages[conversation?._id]?.message || '');

    if (
      (!content ||
        content
          .replaceAll(/<\/?[^>]+(>|$)/g, '')
          .replaceAll(/&nbsp;?/g, '')
          .trim() === '') &&
      !draftMessages[conversation?._id]?.attachment
    ) {
      return;
    }

    const attachmentImageKey: IAttachmentType[] = [];

    if (draftMessages[conversation?._id]?.attachment) {
      setIsUploadingLoading(true);
      const attachmentImage = draftMessages[conversation?._id]?.attachment;
      try {
        const key = await generateUrlAndFileUpload(attachmentImage as File);
        const attachment: IAttachmentType = {
          id: key,
          url: '',
          thumbnail: '',
          imageKey: key,
          title: attachmentImage?.name || '',
          fileType: attachmentImage?.type || '',
        };
        attachmentImageKey.push(attachment);
      } catch (_err) {
        showToast('Error while uploading!', 'error');
        return;
      } finally {
        setIsUploadingLoading(false);
      }
    }

    const createMessageInput: IMessage = {
      content: !content || content !== '<p><br></p>' ? content : '',
      conversationId: conversation._id || '',
      sentAt: new Date().toISOString(),
      sender: {
        id: currentUser.oktaId,
        name: currentUser.name,
        email: currentUser.email,
        schoolId: currentUser?.schoolId || '',
        schoolGrades: currentUser?.grades || [],
        avatarUrl: currentUser?.avatarUrl || '',
        districtId: currentUser?.districtId || '',
        witsbyContractGroupId: currentUser?.witsbyContractGroupId || '',
        organization: {
          name: currentUser.organization.id,
          id: currentUser.organization.name,
        },
      },
      readBy: [
        {
          userId: currentUser.oktaId,
          readAt: new Date().toISOString(),
        },
      ],
      attachments: attachmentImageKey,
    };

    handleMessageCache({
      createMessage: {
        __typename: 'Message',
        attachments: undefined,
        deletedAt: null,
        editedAt: null,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        ...createMessageInput,
        _id: `SENDING_${new Date().toISOString()}`,
      } as unknown as IMessage,
    });

    createMessage({
      variables: { createMessageInput },
    }).then(() => {
      if (isTyping) {
        if (socket) socket.emit('onTypingStop', { conversationId: conversation?._id });
        setIsTyping(false);
      }
    });
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      handleSendMessage();
      const quillEditor = event.currentTarget.querySelector('.ql-editor') as HTMLInputElement;
      if (quillEditor) {
        quillEditor.blur(); // Remove focus from the editor
      }
    }
    if (event.key === 'Escape' || event.code === 'Escape') {
      event.preventDefault();
      event.stopPropagation();
      const quillEditor = event.currentTarget.querySelector('.ql-editor') as HTMLInputElement;
      if (quillEditor) {
        quillEditor.blur(); // Remove focus from the editor
      }
      if (sendButtonRef?.current) {
        sendButtonRef.current.focus();
      }
    }
  };

  useEffect(() => {
    const handlePaste = (event: ClipboardEvent) => {
      const items = get(event.clipboardData, 'items') || get(window, 'clipboardData.items'); // For cross-browser compatibility
      if (!items) return;

      for (const item of items) {
        if (item.kind === 'file') {
          const blob = item.getAsFile();
          if (blob) {
            if (sendButtonRef.current) {
              sendButtonRef.current.focus();
            }

            if (conversation?._id) {
              dispatch({
                data: {
                  ...draftMessages,
                  [conversation?._id]: {
                    ...draftMessages[conversation?._id],
                    attachment: Object.assign(blob, {
                      preview: URL.createObjectURL(blob),
                    }),
                  },
                },
                type: 'DRAFT_MESSAGES' as eChatActionType.DRAFT_MESSAGES,
              });
            }
          }

          // Do something with the file, like upload or process it
        }
      }
    };

    document.addEventListener('paste', handlePaste);
    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, [conversation?._id, dispatch, draftMessages]);

  const onAttachmentDrop = useCallback(
    (files: File[]) => {
      const file = files[0];
      if (!file) return;
      if (sendButtonRef.current) {
        sendButtonRef.current.focus();
      }

      if (conversation?._id) {
        dispatch({
          data: {
            ...draftMessages,
            [conversation?._id]: {
              ...draftMessages[conversation?._id],
              attachment: Object.assign(file, {
                preview: URL.createObjectURL(file),
              }),
            },
          },
          type: 'DRAFT_MESSAGES' as eChatActionType.DRAFT_MESSAGES,
        });
      }
    },
    [conversation?._id, dispatch, draftMessages, sendButtonRef],
  );

  const { getRootProps: attachmentGetRootProps, getInputProps: attachmentGetInputProps } =
    useDropzone({
      minSize: 0,
      maxFiles: 1,
      maxSize: 52428800, // Byte => 50 MB
      onDrop: onAttachmentDrop,
      accept: {
        'text/csv': [],
        'audio/mp3': [],
        'audio/m4a': [],
        'audio/mp4': [],
        'audio/ogg': [],
        'audio/wav': [],
        'audio/wma': [],
        'audio/aac': [],
        'image/jpg': [],
        'image/png': [],
        'image/gif': [],
        'audio/mpeg': [],
        'audio/flac': [],
        'image/jpeg': [],
        'image/webp': [],
        'application/pdf': [],
        'application/ppt': [],
        'application/xml': [],
        'application/msword': [],
        'application/xhtml+xml': [],
        'application/vnd.ms-excel': [],
        'aplication/vnd.ms-powerpoint': [],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
        'text/plain': ['.rt', '.srt', '.txt', '.sub', '.vtt', '.lrc', '.cap', '.ttml', '.mpsub'],
      },
    });

  const renderAttachmentPreview = (conversationId: string) => {
    const attachment = draftMessages[`${conversationId}`]?.attachment;
    if (!attachment) return null;

    const { preview, type, name } = attachment;

    if (type.startsWith('image/')) {
      return (
        <img
          width={100}
          height={100}
          src={preview}
          alt="Preview Attachment"
          title={name || 'Preview Title'}
        />
      );
    }

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', pr: 3 }}>
        <SvgIcon
          icon="ATTACH"
          {...(type === 'application/pdf' && {
            icon: 'PDF_ATTACHMENT',
            viewBox: '0 0 50 64',
          })}
          {...(type.startsWith('video/') && {
            icon: 'VIDEO',
          })}
          {...(type.startsWith('audio/') && {
            icon: 'AUDIO',
          })}
          sx={{
            '&:focus': { outline: 'none' },
            '&:focus-visible': {
              outline: 'none',
              borderRadius: 10,
              background: (theme) => theme.palette.grey[500],
            },
          }}
        />
        {name}
      </Box>
    );
  };

  return (
    <Box
      data-testid="message-input"
      sx={(theme) => ({
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        m: theme.spaces.SMALL,
        background: theme.palette.grey[600],
        border: `1px solid ${theme.palette.grey.A200}`,
        borderRadius: 2,
        '.quill ': { display: 'flex', flexDirection: 'column-reverse' },
        '.ql-editor ': {
          padding: '10px 45px 0px 10px',
        },
        '.ql-container': {
          border: 'none',
          wordBreak: 'break-all',
          wordWrap: 'break-word',
          maxHeight: '160px',
          overflowY: 'scroll',
        },
        '.ql-toolbar': {
          ' .ql-stroke': {
            strokeWidth: 1,
          },
          border: 'none',
          display: { xs: 'unset', sm: 'flex' },
          // justifyContent: 'space-between',
          '&::after': {
            display: 'none',
          },
          [theme.breakpoints.only('xs')]: {
            button: {
              px: 0.4,
              width: 'auto',
            },
          },
        },
        '.ql-toolbar.ql-snow .ql-formats': {
          marginRight: theme.spaces.NONE,
        },
      })}>
      <Box>
        {conversation?._id && draftMessages[conversation?._id]?.attachment?.preview && (
          <Box
            sx={{
              position: 'relative',
              mb: 0.5,
              '& img': {
                p: '1px',
                width: 100,
                maxHeight: 100,
                borderRadius: 3,
                objectFit: 'contain',
                margin: '1px',
                '@media (max-height: 900px)': {
                  maxHeight: 100,
                  objectFit: 'contain',
                },
              },
              '&:hover .attachment-image': {
                display: 'flex',
              },
            }}>
            <CancelIcon
              color="error"
              viewBox="0 0 30 30"
              sx={(theme) => ({
                top: 6,
                right: 0,
                zIndex: 99,
                display: 'none',
                cursor: isUploadLoading ? 'no-drop' : 'pointer',
                position: 'absolute',
                fontSize: theme.font.size.X_LARGE,
              })}
              className="attachment-image"
              onClick={() => {
                if (conversation?._id && !isUploadLoading) {
                  dispatch({
                    data: {
                      ...draftMessages,
                      [conversation?._id]: {
                        ...draftMessages[conversation?._id],
                        attachment: undefined,
                      },
                    },
                    type: 'DRAFT_MESSAGES' as eChatActionType.DRAFT_MESSAGES,
                  });
                }
              }}
            />
            {renderAttachmentPreview(conversation?._id)}
          </Box>
        )}
      </Box>
      <Box sx={{ width: '100%', marginRight: 10 }}>
        <ChatQuill
          theme="snow"
          id="send-message-now"
          onKeyDown={handleKeyDown}
          disabled={isUploadLoading}
          onChange={handleInputChange}
          placeholder="Type your message..."
          activeConversationId={conversation?._id}
          value={
            conversation?._id && draftMessages[conversation?._id]?.message
              ? getQuillContent(draftMessages[conversation?._id].message)
              : ''
          }
          // style={{ flex: 1, marginRight: 10 }}
        />
      </Box>
      {isUploadLoading ? (
        <Box
          sx={{
            top: 10,
            right: '1.3rem',
            position: 'absolute',
          }}>
          <Loader size={20} />
        </Box>
      ) : (
        <>
          {conversation?._id && (
            <IconButton
              tabIndex={0}
              ref={sendButtonRef}
              onClick={handleSendMessage}
              sx={{
                top: 0,
                right: '0.75rem',
                position: 'absolute',
                //             content !== '<p><br></p>' &&
                // content.replaceAll('&nbsp;', '') !== '<p></p>' &&
                // content.replaceAll('<p><br></p>', '') !== '' &&
                opacity:
                  (!draftMessages[conversation?._id]?.message ||
                    trim(draftMessages[conversation?._id]?.message)
                      .replaceAll(/<\/?[^>]+(>|$)/g, '')
                      .replaceAll(/&nbsp;?/g, '')
                      .trim() === '') &&
                  !draftMessages[conversation?._id]?.attachment
                    ? 0.5
                    : 1,
                cursor:
                  (!draftMessages[conversation?._id]?.message ||
                    trim(draftMessages[conversation?._id]?.message) === '<p><br></p>') &&
                  !draftMessages[conversation?._id]?.attachment
                    ? 'not-allowed'
                    : 'pointer',
              }}>
              <SvgIcon
                icon="SEND_MESSAGE"
                sx={(theme) => ({ fontSize: theme.font.size.X_LARGE })}
              />
            </IconButton>
          )}
          <Box
            {...attachmentGetRootProps()}
            className="box"
            sx={{
              bottom: 0,
              right: '1rem',
              cursor: 'pointer',
              position: 'absolute',
            }}>
            <input {...attachmentGetInputProps()} title="Upload Attachment Image" />
            <AttachFileIcon
              viewBox="0 0 30 30"
              sx={(theme) => ({
                color: theme.palette.grey[700],
                fontSize: theme.font.size.X_LARGE,
              })}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default SendMessage;
