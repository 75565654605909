import { useContext, useMemo } from 'react';
import { useRouter } from 'next/router';
import { IConversation, IParticipants, SvgIcon } from '@ascd/witsby-components';
import { Person } from '@mui/icons-material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import { Box, BoxProps, ListItemText, ListItemButton } from '@mui/material';
import { trim } from 'lodash';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { Avatar } from '@components/ChatAndCommunication/Avatar';
import { AppContext, SocketContext } from '@contexts';
import { ChatContext, eChatActionType } from '@contexts/chatContext';

export interface IConversationsProps extends BoxProps {
  isDisabled?: boolean;
  isDirectMessage: boolean;
  conversation: IConversation;
  unreadCountByConversations?: { [key: string]: number };
}

const ConversationItem = ({
  conversation,
  isDisabled = false,
  isDirectMessage = false,
  unreadCountByConversations,
}: IConversationsProps): JSX.Element => {
  const router = useRouter();
  const { state, dispatch } = useContext(ChatContext);
  const { onlineUsers, typingUsers } = useContext(SocketContext);
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const { detailsPage, draftMessages } = state;

  const isPopUpChat = router.pathname !== '/chat-and-communication';

  const activeConversation: IConversation = get(
    detailsPage,
    'detailsPageData.conversation',
    {},
  ) as IConversation;

  const isParticipantTyping = useMemo(() => {
    const isTyping = !isEmpty(typingUsers[`${conversation._id}`]);
    if (!isTyping) return isTyping;

    const users = uniqBy(typingUsers[`${conversation._id}`], 'id').filter(
      (u) => u.id !== currentUser.oktaId,
    );
    return !isEmpty(users);
  }, [conversation._id, currentUser.oktaId, typingUsers]);

  const isActiveConversation =
    conversation?._id === activeConversation?._id &&
    (detailsPage?.type === 'CHAT' || detailsPage?.type === 'CHAT_DETAILS');

  const content = conversation?._id ? trim(draftMessages[conversation?._id]?.message || '') : '';
  const showDraftIcon =
    conversation?._id &&
    ((content &&
      content
        .replaceAll(/<\/?[^>]+(>|$)/g, '')
        .replaceAll(/&nbsp;?/g, '')
        .trim() !== '') ||
      draftMessages[conversation?._id]?.attachment) &&
    activeConversation._id !== conversation?._id;

  const unreadCount = get(unreadCountByConversations, `${conversation._id}`, 0);
  const notifyInfo = get(currentUser, 'preferences.chat.notificationPreferences', {
    messageBadge: true,
    soundNotification: true,
    emailNotification: false,
  });

  let user: IParticipants | undefined;
  if (isDirectMessage) {
    user = conversation.participants.find((p) => p.userInfo.id !== currentUser.oktaId);
  }

  const getIconOrAvatar = () => {
    if (isParticipantTyping) {
      return (
        <Box
          className="conversation-item"
          sx={{
            textAlign: 'center',
            width: (theme) => theme.size.LARGE,
            '.dots::after': {
              pl: 0.2,
              content: '""',
              animation: 'dots 1.5s steps(3, end) infinite',
            },
            '@keyframes dots': {
              '0%': { content: '""' },
              '33%': { content: '"."' },
              '66%': { content: '".."' },
              '100%': { content: '"..."' },
            },
          }}>
          <span className="dots" />
        </Box>
      );
    }
    if (isDirectMessage) {
      return (
        <Avatar
          showOnlineStatus
          alt={conversation.title}
          src={conversation.avatar}
          imgProps={{
            className: 'conversation-item',
          }}
          sx={(theme) => ({
            width: theme.size.LARGE,
            height: theme.size.LARGE,
            fontSize: theme.font.size.SMALL,
          })}
          isOnline={onlineUsers.includes(user?.userInfo?.id as string)}
        />
      );
    }
    if (conversation.type === 'GROUP') {
      return <GroupsIcon className="conversation-item" />;
    }

    return <SvgIcon viewBox="0 0 16 17" icon="CONVERSATION_ICON" className="conversation-item" />;
  };

  return (
    <Box
      className="conversation-item"
      sx={(theme) => ({
        ...(isActiveConversation && {
          backgroundColor: isPopUpChat ? theme.palette.grey[500] : theme.palette.grey.A100,
        }),
      })}>
      <ListItemButton
        sx={{ px: 1, py: 0 }}
        className="conversation-item"
        onClick={() => {
          dispatch({
            data: {
              type: 'CHAT',
              detailsPageData: {
                conversation,
              },
            },
            type: 'DETAILS_PAGE' as eChatActionType.DETAILS_PAGE,
          });
        }}>
        <ListItemText
          className="conversation-item"
          sx={(theme) => ({
            '&.MuiListItemText-root': {
              '& span.span-participants': {
                color: theme.palette.grey[300],
                fontSize: theme.font.size.XX_SMALL,
                ...(isDisabled && {
                  color: theme.palette.grey[400],
                }),
              },
            },
          })}
          primary={
            <Box
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                color: theme.palette.grey[200],
                fontSize: theme.font.size.SMALL,
                fontWeight: theme.font.weight.LIGHT,
                svg: {
                  mr: 0.8,
                  color: theme.palette.black[200],
                  fontSize: theme.font.size.LARGE,
                  path: {
                    fill: theme.palette.black[200],
                    ...(isDisabled && {
                      fill: theme.palette.grey[400],
                    }),
                  },
                },
                ...(isDisabled && {
                  color: theme.palette.grey[400],
                }),
              })}>
              {getIconOrAvatar()}
              <Box
                className="conversation-item"
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  maxWidth: '80%', // or specific width
                  ...(isDirectMessage && {
                    ml: 1.2,
                  }),
                }}>
                {conversation.title}
              </Box>
              {!isDirectMessage && (
                <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '10px', ml: 1 }}>
                  (<span className="span-participants">{conversation?.participants?.length}</span>
                  <Person
                    sx={{
                      fontSize: (theme) => `${theme.font.size.SMALL} !important`,
                      mr: '0 !important',
                    }}
                  />
                  )
                </Box>
              )}
            </Box>
          }
        />
        {showDraftIcon && (
          <EditOutlinedIcon sx={{ fontSize: (theme) => theme.font.size.REGULAR }} />
        )}
        {notifyInfo?.messageBadge && unreadCount > 0 && (
          <Box
            className="conversation-item"
            sx={(theme) => ({
              p: 0.5,
              display: 'flex',
              width: '0.7rem',
              height: '0.7rem',
              alignItems: 'center',
              justifyContent: 'center',
              color: theme.palette.white[100],
              fontSize: theme.font.size.X_SMALL,
              borderRadius: theme.border.radius.FULL,
              background: theme.palette.primary.light,
              ...(showDraftIcon && {
                ml: 1,
              }),
            })}>
            {unreadCount}
          </Box>
        )}
      </ListItemButton>
    </Box>
  );
};

export default ConversationItem;
