import { useContext, useRef, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { IConversation, IMessage, Loader } from '@ascd/witsby-components';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { Box } from '@mui/material';
import { cloneDeep, get, groupBy, last, map, sortBy } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { DateTime } from 'luxon';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AppContext } from '@contexts';
import { ChatContext } from '@contexts/chatContext';
import GET_MESSAGES_FROM_CONVERSATION from '@graphql/schema/getMessagesFromConversation.graphql';
import { handleGraphqlError, sleep } from '@utils';
import { DisabledSendMessage } from '../DisabledSendMessage';
import { Message } from '../Message';
import { ParticipantsTyping } from '../ParticipantsTyping';
import { SendMessage } from '../SendMessage';

const Messages = (): JSX.Element => {
  const apolloClient = useApolloClient();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [showNewMessageIndicator, setShowNewMessageIndicator] = useState(false);
  const [unReadMessageFound, setUnReadMessageFound] = useState<IMessage | undefined>(undefined);

  const {
    state: { detailsPage },
  } = useContext(ChatContext);
  const {
    state: { currentUser },
  } = useContext(AppContext);

  const conversation: IConversation = get(
    detailsPage,
    'detailsPageData.conversation',
    {},
  ) as IConversation;

  const blockedUsers: string[] = get(currentUser, 'preferences.chat.blockedUsers', []);
  const blockedByUsers: string[] = get(currentUser, 'preferences.chat.blockedByUsers', []);

  let isBlocked = false;
  let isBlockedByUser = false;
  let showDisabledMessage = '';

  if (conversation.type === 'DIRECT') {
    const user = conversation.participants.find((p) => p.userInfo.id !== currentUser.oktaId);
    isBlocked = blockedUsers?.includes(user?.userInfo?.id as string);
    isBlockedByUser = blockedByUsers?.includes(user?.userInfo?.id as string);

    if (isBlocked) {
      showDisabledMessage = 'You have blocked this user';
    } else if (isBlockedByUser) {
      showDisabledMessage = 'You are blocked by this user';
    }
  } else if (conversation.status === 'INACTIVE' || conversation.chatType === 'NONE') {
    showDisabledMessage = `Chat has been disabled for this ${conversation.type.toLowerCase()} conversation at the moment`;
  } else if (conversation.chatType === 'BROADCAST') {
    const user = conversation.participants.find((p) => p.userInfo.id === currentUser.oktaId);
    if (user?.role === 'MEMBER') {
      showDisabledMessage = 'Only admins can send messages';
    }
  }

  const { loading, data, fetchMore } = useQuery(GET_MESSAGES_FROM_CONVERSATION, {
    fetchPolicy: 'network-only',
    variables: {
      conversationId: conversation?._id,
      filter: {
        limit: 100,
        page: 0,
      },
    },
    onCompleted: async (res) => {
      if (res.error) handleGraphqlError(res.error);
      const messages: IMessage[] = get(data, 'getMessagesFromConversation.messages', []);
      const unReadMessage = [...messages]
        ?.reverse()
        .find(
          (message) => !(message?.readBy ?? []).some((read) => read?.userId === currentUser.oktaId),
        );
      if (unReadMessage && !unReadMessageFound) {
        setUnReadMessageFound(unReadMessage);
        setShowNewMessageIndicator(true);
        const element = document.getElementById(`message-${unReadMessage._id}`);

        if (element) {
          element.scrollIntoView({
            block: 'start',
            inline: 'start',
            behavior: 'auto',
          });

          const extraScroll = -180;
          setTimeout(() => {
            const parent = element?.parentElement;
            if (parent) {
              parent.scrollTop += extraScroll;
            }
          }, 300);
        }
      } else {
        setShowNewMessageIndicator(false);
        setUnReadMessageFound(undefined);
      }
    },
    onError: handleGraphqlError,
  });

  const totalCount = get(data, 'getMessagesFromConversation.totalCount', 0);
  const messages: IMessage[] = get(data, 'getMessagesFromConversation.messages', []);

  // Group messages by date
  const groupedMessages = groupBy(sortBy(messages, 'sentAt') || [], (message) =>
    DateTime.fromISO(message?.sentAt).toISODate(),
  );

  // useEffect(() => {
  //   if (scrollRef?.current && !loading && !showNewMessageIndicator) {
  //     scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  //   }
  // }, [messages, loading, showNewMessageIndicator]);

  const fetchMoreData = (skip: number) => {
    if (loading) return;
    fetchMore({ variables: { filter: { page: Math.round(skip / 100), limit: 100 } } })
      .then(async (res) => {
        const lastMessage = last(messages);
        const element = document.getElementById(`message-${lastMessage?._id}`);
        await sleep(200);
        if (element) {
          element.scrollIntoView({
            block: 'start',
            inline: 'start',
            behavior: 'auto',
          });

          const extraScroll = -80;
          setTimeout(() => {
            const parent = element?.parentElement;
            if (parent) {
              parent.scrollTop += extraScroll;
            }
          }, 300);
        }

        // Read the cached messages for the current conversation
        const cachedMessages = apolloClient.readQuery({
          query: GET_MESSAGES_FROM_CONVERSATION,
          variables: {
            conversationId: conversation?._id,
            filter: { limit: 100, page: 0 },
          },
        });

        // Deep clone the cached messages to avoid direct mutation
        const cloneCachedMessages: IMessage[] = cloneDeep(
          cachedMessages?.getMessagesFromConversation?.messages || [],
        );

        const newMessages = [
          ...cloneCachedMessages,
          ...get(res, 'data.getMessagesFromConversation.messages', []),
        ];

        apolloClient.writeQuery({
          query: GET_MESSAGES_FROM_CONVERSATION,
          variables: {
            conversationId: conversation?._id,
            filter: { limit: 100, page: 0 },
          },
          data: {
            ...cachedMessages,
            getMessagesFromConversation: {
              ...(cachedMessages?.getMessagesFromConversation || {}),
              ...(get(res, 'data.getMessagesFromConversation', {}) || {}),
              messages: newMessages,
            },
          },
        });
      })
      .catch(handleGraphqlError);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        maxWidth: '100%',
        p: { xs: 0.5, md: 1.5 },
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: { xs: 'calc(100% - 8px)', md: 'calc(100% - 24px)' },
      }}>
      <Box
        ref={scrollRef}
        id="messages-scroll"
        sx={{
          overflow: 'scroll',
          height: 'calc(100% - 49px)',
          ...(!loading && {
            display: 'flex',
            flexDirection: 'column-reverse',
          }),
        }}>
        {loading && <Loader isFullScreen size={30} />}
        {!loading && isEmpty(messages) && (
          <Box
            sx={(theme) => ({
              mb: 2,
              height: '90%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              color: theme.palette.grey[200],
              fontSize: theme.font.size.SMALL,
              svg: {
                fontSize: theme.font.size.H2,
              },
              div: {
                fontSize: theme.font.size.REGULAR,
              },
              span: {
                color: theme.palette.grey[300],
              },
            })}>
            <QuestionAnswerOutlinedIcon />
            <div>No conversation yet</div>
            {!showDisabledMessage && <span>Send a message to start the chat!</span>}
          </Box>
        )}
        {!loading && (
          <InfiniteScroll
            inverse
            dataLength={messages?.length}
            scrollableTarget="messages-scroll"
            hasMore={messages?.length < totalCount}
            loader={<Loader isFullScreen size={25} />}
            next={() => fetchMoreData(messages?.length)}
            style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column-reverse' }}>
            <Box>
              {map(groupedMessages || [], (groupMessages, date) => {
                const currentDate = DateTime.fromISO(date);
                const today = DateTime.local().toISODate();
                const yesterday = DateTime.local().minus({ days: 1 }).toISODate();

                let formattedDate;
                if (date === today) {
                  formattedDate = 'Today';
                } else if (date === yesterday) {
                  formattedDate = `Yesterday, ${currentDate.toFormat('LLLL dd, yyyy')}`;
                } else {
                  formattedDate = currentDate.toFormat('EEEE, LLLL dd, yyyy');
                }

                return (
                  <Box key={date}>
                    <Box
                      sx={(theme) => ({
                        my: 0.5,
                        textAlign: 'center',
                        color: theme.palette.grey[200],
                        fontSize: theme.font.size.X_SMALL,
                        fontWeight: theme.font.weight.REGULAR,
                        span: {
                          px: 1.5,
                          py: 0.4,
                          borderRadius: theme.border.radius.LARGE,
                          backgroundColor: theme.palette.grey[500],
                        },
                      })}>
                      <span>{formattedDate}</span>
                    </Box>
                    {map(groupMessages, (message) => (
                      <Box key={message._id} sx={{ mb: 2 }}>
                        <Message
                          message={message}
                          unReadMessage={unReadMessageFound}
                          onImageLoaded={() => {
                            if (scrollRef?.current && !loading && !showNewMessageIndicator) {
                              scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
                            }
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                );
              })}
            </Box>
          </InfiniteScroll>
        )}
      </Box>
      <Box>
        <ParticipantsTyping conversationId={conversation?._id || ''} />
        {!showDisabledMessage && (
          <SendMessage
            onSend={() => {
              if (scrollRef?.current) {
                scrollRef?.current?.scrollTo({
                  top: scrollRef.current.scrollHeight,
                  behavior: 'smooth',
                });
              }
              // if (scrollRef?.current) {
              //   scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
              // }
            }}
          />
        )}
        {showDisabledMessage && <DisabledSendMessage text={showDisabledMessage} />}
      </Box>
    </Box>
  );
};

export default Messages;
