import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Button, IConversation, SvgIcon } from '@ascd/witsby-components';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box, Divider, Link } from '@mui/material';
import get from 'lodash/get';
import { AppContext, eActionType } from '@contexts';
import { ChatContext, eChatActionType } from '@contexts/chatContext';
import BLOCK_UNBLOCK_USER from '@graphql/schema/mutations/blockOrUnblockUser.graphql';
import { handleGraphqlError } from '@utils';
import { Avatar } from '../Avatar';
import { ChatLayout } from '../ChatLayout';
import { Participants } from '../Participants';

const ChatDetails = (): JSX.Element => {
  const {
    dispatch: chatDispatch,
    state: { detailsPage },
  } = useContext(ChatContext);
  const {
    dispatch,
    state: { currentUser },
  } = useContext(AppContext);

  const blockedUsers: string[] = get(currentUser, 'preferences.chat.blockedUsers', []);

  const conversation: IConversation = get(
    detailsPage,
    'detailsPageData.conversation',
    {},
  ) as IConversation;

  const isDirectConversation = conversation.type === 'DIRECT';

  const user = conversation.participants.find((p) => p.userInfo.id !== currentUser.oktaId);
  const isBlocked = blockedUsers?.includes(user?.userInfo?.id as string);

  const dispatchCurrentUser = (res: undefined) => {
    dispatch({
      type: eActionType.CURRENT_USER,
      data: {
        ...currentUser,
        ...get(res, 'blockOrUnblockUser', {}),
      },
    });
  };

  const [updateBlockUnBlockUser, { loading }] = useMutation(BLOCK_UNBLOCK_USER, {
    onError: handleGraphqlError,
    onCompleted: dispatchCurrentUser,
  });

  const onCloseIconClick = () => {
    chatDispatch({
      data: {
        type: 'CHAT',
        detailsPageData: { conversation },
      },
      type: 'DETAILS_PAGE' as eChatActionType.DETAILS_PAGE,
    });
  };

  return (
    <ChatLayout
      type="CHAT_DETAILS"
      title={conversation.title}
      titleIcon={
        isDirectConversation ? (
          <Avatar
            alt={conversation.title}
            src={conversation.avatar}
            sx={(theme) => ({
              mr: 1,
              width: theme.size.LARGE,
              height: theme.size.LARGE,
              fontSize: theme.font.size.SMALL,
            })}
          />
        ) : (
          <SvgIcon
            icon="CONVERSATION_ICON"
            viewBox="0 0 16 17"
            sx={(theme) => ({
              fontSize: theme.font.size.LARGE,
            })}
          />
        )
      }
      headerChildren={
        <CloseOutlinedIcon
          tabIndex={0}
          onClick={onCloseIconClick}
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === ' ') {
              onCloseIconClick();
            }
          }}
          sx={(theme) => ({
            cursor: 'pointer',
            color: theme.palette.grey[700],
            background: theme.palette.white[100],
            borderRadius: theme.border.radius.FULL,
            width: theme.size.LARGE,
            height: theme.size.LARGE,
            fontSize: theme.font.size.REGULAR,
            '&:focus': {
              borderRadius: '50%',
              outlineColor: theme.palette.primary.main,
            },
          })}
        />
      }>
      {isDirectConversation && (
        <Box
          sx={{
            mt: 5,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
          <Avatar
            alt={conversation.title}
            src={conversation.avatar}
            sx={(theme) => ({
              mb: 1,
              width: theme.font.size.H1,
              height: theme.font.size.H1,
              fontSize: theme.font.size.H3,
            })}
          />
          <Box
            sx={(theme) => ({
              textAlign: 'center',
              color: theme.palette.grey[200],
              fontSize: theme.font.size.REGULAR,
              a: {
                color: 'inherit',
                textDecoration: 'none',
                fontSize: theme.font.size.SMALL,
                '&:hover': {
                  textDecoration: 'underline',
                  color: theme.palette.primary.main,
                },
              },
            })}>
            {conversation.title}
            <div>
              <Link href={`mailto:${user?.userInfo?.email}`} target="_blank">
                {user?.userInfo?.email}
              </Link>
            </div>
            <div>
              <Button
                autoFocus
                size="small"
                loading={loading}
                onClick={() => {
                  updateBlockUnBlockUser({ variables: { oktaId: user?.userInfo?.id } });
                }}
                sx={(theme) => ({
                  px: 4,
                  mt: 3,
                  py: 0.5,
                  color: theme.palette.white[100],
                  borderRadius: theme.border.radius.SMALL,
                  borderColor: theme.palette.primary.main,
                  backgroundColor: theme.palette.primary.main,
                  '&.Mui-disabled': {
                    opacity: 0.7,
                    color: theme.palette.white[100],
                  },
                  '&:hover, &:active, &:focus': {
                    color: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                    backgroundColor: theme.palette.white[100],
                  },
                  ...(isBlocked && {
                    color: theme.palette.error.light,
                    borderColor: theme.palette.error.light,
                    backgroundColor: theme.palette.white[100],
                    '&:hover, &:active, &:focus': {
                      color: theme.palette.white[100],
                      borderColor: theme.palette.white[100],
                      backgroundColor: theme.palette.error.light,
                    },
                    svg: {
                      color: theme.palette.white[100],
                    },
                  }),
                })}>
                {isBlocked ? 'Unblock' : 'Block'} {conversation.title}
              </Button>
            </div>
          </Box>
        </Box>
      )}
      {!isDirectConversation && (
        <Box sx={{ height: '100%' }}>
          <Box sx={(theme) => ({ py: 1.5, px: 2, fontSize: theme.font.size.REGULAR })}>
            Participant
            {conversation?.participants?.length !== 1 ? 's' : ''}
          </Box>
          <Divider />
          <Box sx={{ height: '100%', p: 2 }}>
            <Participants participants={conversation?.participants?.map((p) => p.userInfo)} />
          </Box>
        </Box>
      )}
    </ChatLayout>
  );
};

export default ChatDetails;
