import { useContext, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, IConversation } from '@ascd/witsby-components';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box, Divider, Checkbox, Typography, FormControl, FormControlLabel } from '@mui/material';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { AppContext, eActionType } from '@contexts';
import { ChatContext, eChatActionType } from '@contexts/chatContext';
import UPDATE_USER_DETAILS from '@graphql/schema/mutations/updateUserDetails.graphql';
import { handleGraphqlError, showToast } from '@utils';
import { ChatLayout } from '../ChatLayout';

const notifyOptions = [
  { value: 'EMAIL', label: 'Email notification' },
  { value: 'SOUND', label: 'Sound notification' },
  { value: 'MESSAGE_BADGE', label: 'Show a message badge' },
];

const ChatSettings = (): JSX.Element => {
  const {
    dispatch,
    state: { currentUser },
  } = useContext(AppContext);
  const {
    state: { detailsPage },
    dispatch: chatDispatch,
  } = useContext(ChatContext);

  const conversation: IConversation = get(
    detailsPage,
    'detailsPageData.conversation',
    {},
  ) as IConversation;

  const notifyInfo = get(currentUser, 'preferences.chat.notificationPreferences', {
    messageBadge: true,
    soundNotification: true,
    emailNotification: false,
  });

  const defaultNotifyValues = useMemo(() => {
    const values = [];
    if (notifyInfo?.emailNotification) values.push('EMAIL');
    if (notifyInfo?.messageBadge) values.push('MESSAGE_BADGE');
    if (notifyInfo?.soundNotification) values.push('SOUND');
    return values;
  }, [notifyInfo]);

  const [canSave, setCanSave] = useState(false);
  const [notifyValues, setNotifyValues] = useState<string[]>(defaultNotifyValues);

  const onNotifyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!canSave) setCanSave(true);
    const { value, checked } = event.target;
    let newNotifyValues;

    if (checked) {
      newNotifyValues = [...notifyValues, value];
    } else {
      newNotifyValues = notifyValues.filter((item) => item !== value);
    }
    setNotifyValues(newNotifyValues);
  };

  const dispatchCurrentUser = (res: undefined) => {
    dispatch({
      type: eActionType.CURRENT_USER,
      data: {
        ...currentUser,
        ...get(res, 'updateUserDetails', {}),
      },
    });
  };

  const [updateUserDetailsQuery, { loading }] = useMutation(UPDATE_USER_DETAILS, {
    onError: handleGraphqlError,
    onCompleted: dispatchCurrentUser,
  });

  const handleSave = () => {
    let emailNotification = false;
    let soundNotification = false;
    let messageBadge = false;
    if (notifyValues.includes('EMAIL')) {
      emailNotification = true;
    }
    if (notifyValues.includes('MESSAGE_BADGE')) {
      messageBadge = true;
    }
    if (notifyValues.includes('SOUND')) {
      soundNotification = true;
    }

    updateUserDetailsQuery({
      variables: {
        updateUserDetailsInput: {
          oktaId: currentUser.oktaId,
          preferences: {
            ...currentUser.preferences,
            chat: {
              ...currentUser.preferences?.chat,
              notificationPreferences: {
                emailNotification,
                soundNotification,
                messageBadge,
              },
            },
          },
        },
      },
    }).then(() => {
      setCanSave(false);
      showToast('Settings saved successfully');
    });
  };

  const onCloseIconClick = () => {
    chatDispatch({
      data: {
        type: 'CHAT',
        detailsPageData: { conversation },
      },
      type: 'DETAILS_PAGE' as eChatActionType.DETAILS_PAGE,
    });
  };

  return (
    <ChatLayout
      title="Settings"
      {...(!isEmpty(conversation) && {
        headerChildren: (
          <CloseOutlinedIcon
            tabIndex={0}
            onClick={onCloseIconClick}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                onCloseIconClick();
              }
            }}
            sx={(theme) => ({
              cursor: 'pointer',
              color: theme.palette.grey[700],
              background: theme.palette.white[100],
              borderRadius: theme.border.radius.FULL,
              width: theme.size.LARGE,
              height: theme.size.LARGE,
              fontSize: theme.font.size.REGULAR,
            })}
          />
        ),
      })}>
      <Box sx={{ p: 2 }}>
        <Typography
          sx={(theme) => ({
            mb: theme.spaces.REGULAR,
            fontSize: theme.font.size.REGULAR,
            fontWeight: theme.font.weight.BOLD,
          })}>
          Notification preferences
        </Typography>
        <Divider sx={{ mb: (theme) => theme.spaces.REGULAR }} />
        <FormControl>
          {notifyOptions.map((option, index) => (
            <FormControlLabel
              key={`notify_${index + 1}`}
              sx={(theme) => ({ fontSize: theme.font.size.SMALL })}
              control={
                <Checkbox
                  size="small"
                  value={option.value}
                  autoFocus={index === 0}
                  onChange={onNotifyChange}
                  checked={notifyValues.includes(option.value)}
                  sx={(theme) => ({
                    color: theme.palette.grey[400],
                    '&.Mui-checked': {
                      color: theme.palette.primary.light,
                    },
                  })}
                />
              }
              label={
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.font.size.SMALL,
                    color: theme.palette.grey[200],
                    display: 'inline-block',
                    cursor: 'default',
                    wordBreak: 'break-word',
                  })}>
                  {option.label}
                </Typography>
              }
            />
          ))}
        </FormControl>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', p: (theme) => theme.spaces.LARGE }}>
        <Button
          title="Save"
          loading={loading}
          disabled={!canSave}
          onClick={handleSave}
          sx={(theme) => ({
            px: 4,
            py: 0.5,
            color: theme.palette.white[100],
            borderRadius: theme.border.radius.SMALL,
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main,
            '&.Mui-disabled': {
              opacity: 0.7,
              color: theme.palette.white[100],
            },
            '&:hover, &:active, &:focus': {
              color: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              backgroundColor: theme.palette.white[100],
            },
          })}>
          Save
        </Button>
      </Box>
    </ChatLayout>
  );
};

export default ChatSettings;
