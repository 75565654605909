import { useCallback, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useApolloClient } from '@apollo/client';
import { IConversation, IMessage } from '@ascd/witsby-components';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { AppContext, SocketContext } from '@contexts';
import { ChatContext, eChatActionType } from '@contexts/chatContext';
import GET_CONVERSATIONS_FROM_USER_ID from '@graphql/schema/getConversationsFromUserId.graphql';
import GET_MESSAGES_FROM_CONVERSATION from '@graphql/schema/getMessagesFromConversation.graphql';
import { commonConversationsData, commonConversationsFilter } from '@utils';

const ChatSocket = () => {
  const router = useRouter();
  const apolloClient = useApolloClient();
  const { socket } = useContext(SocketContext);
  const {
    dispatch,
    state: { detailsPage },
  } = useContext(ChatContext);
  const {
    state: { currentUser },
  } = useContext(AppContext);

  const isPopUpChat = router.pathname !== '/chat-and-communication';

  const notifyInfo = get(currentUser, 'preferences.chat.notificationPreferences', {
    messageBadge: true,
    soundNotification: true,
    emailNotification: false,
  });
  const activeConversation: IConversation = get(
    detailsPage,
    'detailsPageData.conversation',
    {},
  ) as IConversation;

  const handleConversationCache = useCallback(
    ({
      createConversation,
      isUpdate = false,
    }: {
      createConversation: IConversation;
      isUpdate?: boolean;
    }) => {
      ['GROUP', 'ASSIGNMENT', 'DIRECT'].forEach((type) => {
        // Read the cached conversation data for the current user
        const cachedAllConversationsDataList = apolloClient.readQuery({
          query: GET_CONVERSATIONS_FROM_USER_ID,
          ...commonConversationsFilter(currentUser.oktaId, type, false),
        });

        const conversationsLists = get(
          cachedAllConversationsDataList,
          'getConversationsFromUserId',
          commonConversationsData,
        );

        if (createConversation.type === type) {
          // Deep clone the conversations to avoid direct mutation
          const cloneConversations: IConversation[] = cloneDeep(
            cachedAllConversationsDataList?.getConversationsFromUserId?.conversations || [],
          );

          if (
            !conversationsLists?.conversations?.some(
              (c: IConversation) => c._id === createConversation._id,
            )
          ) {
            // Push the new conversation
            cloneConversations.push(createConversation);
          } else if (isUpdate) {
            const conversationIndex = cloneConversations.findIndex(
              (c) => c._id === createConversation._id,
            );

            if (conversationIndex > -1) {
              const currentConversation = cloneConversations[`${conversationIndex}`];
              let title = '';
              let avatar = '';
              if (currentConversation.type === 'DIRECT') {
                const userName = currentConversation.participants.find(
                  (participant) => participant.userInfo.id !== currentUser.oktaId,
                );
                if (userName) {
                  title = userName.userInfo.name || currentConversation.title || '';
                  avatar = userName.userInfo?.avatarUrl || currentConversation?.avatar || '';
                }
              }
              const updatedConversation = {
                ...currentConversation,
                ...createConversation,
                ...(currentConversation.type === 'DIRECT' && {
                  title,
                  avatar,
                }),
                lastMessageSentAt:
                  createConversation?.lastMessageSentAt || currentConversation?.lastMessageSentAt,
              };
              cloneConversations[`${conversationIndex}`] = updatedConversation;
              if (activeConversation?._id === createConversation._id) {
                dispatch({
                  data: {
                    type: detailsPage?.type,
                    detailsPageData: { conversation: updatedConversation },
                  },
                  type: 'DETAILS_PAGE' as eChatActionType.DETAILS_PAGE,
                });
              }
            }
          }

          // Write the updated conversations back to the cache
          apolloClient.writeQuery({
            query: GET_CONVERSATIONS_FROM_USER_ID,
            ...commonConversationsFilter(currentUser.oktaId, type, false),
            data: {
              ...cachedAllConversationsDataList,
              getConversationsFromUserId: {
                ...(cachedAllConversationsDataList?.getConversationsFromUserId || {}),
                conversations: cloneConversations,
              },
            },
          });
        }
      });
    },
    [activeConversation, apolloClient, currentUser.oktaId, detailsPage?.type, dispatch],
  );

  const handleMessageCache = useCallback(
    ({ createMessage }: { createMessage: IMessage }) => {
      const { conversationId } = createMessage;

      // Check if the new message belongs to the current conversation
      if (activeConversation?._id !== conversationId) {
        ['GROUP', 'ASSIGNMENT', 'DIRECT'].forEach((type) => {
          // Read the cached conversation data for the current user
          const cachedAllConversationsDataList = apolloClient.readQuery({
            query: GET_CONVERSATIONS_FROM_USER_ID,
            ...commonConversationsFilter(currentUser.oktaId, type, false),
          });

          const conversationsLists = get(
            cachedAllConversationsDataList,
            'getConversationsFromUserId',
            commonConversationsData,
          );

          let totalUnreadCount = conversationsLists?.totalUnreadCount || 0;

          if (
            conversationsLists?.conversations?.some((c: IConversation) => c._id === conversationId)
          ) {
            // Deep clone the unread counts to avoid direct mutation
            const cloneUnreadCountByConversations = cloneDeep(
              cachedAllConversationsDataList?.getConversationsFromUserId
                ?.unreadCountByConversations || {},
            );
            if (!isPopUpChat && notifyInfo?.soundNotification) {
              const sound = new Audio('/notification.mp3');
              sound.play();
            }
            totalUnreadCount += 1;

            // Increment the unread count for the conversation
            cloneUnreadCountByConversations[`${conversationId}`] =
              (cloneUnreadCountByConversations[`${conversationId}`] || 0) + 1;

            // Write the updated unread counts back to the cache
            apolloClient.writeQuery({
              query: GET_CONVERSATIONS_FROM_USER_ID,
              ...commonConversationsFilter(currentUser.oktaId, type, false),
              data: {
                ...cachedAllConversationsDataList,
                getConversationsFromUserId: {
                  ...(cachedAllConversationsDataList?.getConversationsFromUserId || {}),
                  unreadCountByConversations: cloneUnreadCountByConversations,
                  totalUnreadCount,
                },
              },
            });
          }
        });

        return;
      }

      // Read the cached messages for the current conversation
      const cachedMessages = apolloClient.readQuery({
        query: GET_MESSAGES_FROM_CONVERSATION,
        variables: {
          conversationId,
          filter: {
            limit: 100,
            page: 0,
          },
        },
      });

      // Deep clone the cached messages to avoid direct mutation
      const cloneCachedMessages: IMessage[] = cloneDeep(
        cachedMessages?.getMessagesFromConversation?.messages || [],
      );

      // Add the new message to the cloned messages array
      cloneCachedMessages.push(createMessage);

      // Write the updated messages back to the cache
      apolloClient.writeQuery({
        query: GET_MESSAGES_FROM_CONVERSATION,
        variables: {
          conversationId,
          filter: {
            limit: 100,
            page: 0,
          },
        },
        data: {
          ...cachedMessages,
          getMessagesFromConversation: {
            ...(cachedMessages?.getMessagesFromConversation || {}),
            messages: cloneCachedMessages,
          },
        },
      });
    },
    [apolloClient, currentUser.oktaId, activeConversation?._id, notifyInfo?.soundNotification],
  );

  useEffect(() => {
    if (socket) {
      // onMessage
      socket.on('onMessage', (createdMessage: IMessage) => {
        handleMessageCache({
          createMessage: {
            __typename: 'Message',
            attachments: undefined,
            deletedAt: undefined,
            editedAt: undefined,
            ...createdMessage,
          } as unknown as IMessage,
        });
      });

      // onConversationCreate
      socket.on('onConversationCreate', (createConversation: IConversation) => {
        handleConversationCache({
          createConversation: {
            __typename: 'Conversation',
            ...createConversation,
          } as unknown as IConversation,
        });
      });

      // onConversationUpdate
      socket.on('onConversationUpdate', (updatedConversation: IConversation) => {
        handleConversationCache({
          createConversation: {
            __typename: 'Conversation',
            ...updatedConversation,
          } as unknown as IConversation,
          isUpdate: true,
        });
      });
    }

    return () => {
      if (socket) {
        socket.off('onMessage');
        socket.off('onConversationCreate');
        socket.off('onConversationUpdate');
      }
    };
  }, [handleConversationCache, handleMessageCache, socket]);

  return null;
};

export default ChatSocket;
