import { useContext, useEffect, useState } from 'react';
import Router, { useRouter } from 'next/router';
import { Breadcrumbs } from '@ascd/witsby-components';
import { Box, Container, Grid, ThemeProvider } from '@mui/material';
import get from 'lodash/get';
import MyPortfolioLayout from '@components/MyPortfolioLayout';
import { AppContext } from '@contexts';
import { ChatContext, eChatActionType } from '@contexts/chatContext';
import { getChatTheme } from '@utils';
import { Chat } from '../Chat';
import { ChatDetails } from '../ChatDetails';
import { ChatFeatureIsDisabled } from '../ChatFeatureIsDisabled';
import { ChatSettings } from '../ChatSettings';
import { ChatSidebar } from '../ChatSidebar';
import { ChatSocket } from '../ChatSocket';
import { ChooseParticipant } from '../ChooseParticipant';
import { SelectConversation } from '../SelectConversation';

const ChatAndCommunicationWrapper = () => {
  const router = useRouter();
  const {
    query: { redirectTo },
  } = router;

  const {
    dispatch,
    state: { detailsPage },
  } = useContext(ChatContext);
  const {
    state: { currentInstitution },
  } = useContext(AppContext);

  const chatTheme = getChatTheme();

  const [viewportSize, setViewportSize] = useState({
    width: 0,
    height: 0,
  });

  const isMobile = viewportSize.width < 900;

  useEffect(() => {
    const handleResize = () => {
      setViewportSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    if (typeof window !== 'undefined') {
      handleResize(); // Set initial size
      window.addEventListener('resize', handleResize);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  // TO RESET CONTEXT WHILE NAVIGATING
  useEffect(() => {
    const handleRouteChange = () => {
      dispatch({
        type: 'DETAILS_PAGE' as eChatActionType.DETAILS_PAGE,
        data: null,
      });
    };

    Router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      Router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [dispatch]);

  const isChatAndCommunicationEnable = get(
    currentInstitution,
    'settings.chatAndCommunication',
    false,
  );

  const getDetailsReports = () => {
    switch (detailsPage?.type) {
      case 'CHAT':
        return <Chat />;
      case 'CHAT_DETAILS':
        return <ChatDetails />;
      case 'CHAT_SETTINGS':
        return <ChatSettings />;
      case 'CHOOSE_PARTICIPANT':
        return <ChooseParticipant />;
      default:
        return <SelectConversation />;
    }
  };

  const breadcrumbList = [
    {
      icon: 'CHAT_HEADER_ICON',
      pathName: '/chat-and-communication',
      routeName: 'Chat and Communication',
    },
    ...(redirectTo
      ? [
          {
            pathName: redirectTo,
            routeName: 'Go back',
          },
        ]
      : []),
  ];

  return (
    <MyPortfolioLayout
      withSidebar
      showSubHeader={!isMobile}
      titleIcon="CHAT_HEADER_ICON"
      title="Chat and Communication"
      {...(!isMobile && {
        breadCrumbsChildren: (
          <Breadcrumbs
            isLastActive
            routeList={breadcrumbList}
            onClick={(pathName: string | undefined) => router.push(pathName || '/')}
          />
        ),
      })}>
      <ThemeProvider theme={chatTheme}>
        <Box width="100%">
          {isChatAndCommunicationEnable ? (
            <>
              <ChatSocket />
              <Grid gap={2} container display="grid" gridTemplateColumns="repeat(12, 1fr)">
                {/* Sidebar filters for large screen */}
                <Box
                  sx={(theme) => ({
                    [theme.breakpoints.down('md')]: {
                      gridColumn: 'span 12',
                      height: 'calc(100vh - 60px - 10px - 20px)',
                      ...(detailsPage?.type && {
                        display: 'none',
                      }),
                    },
                    [theme.breakpoints.up('sm')]: {
                      minWidth: 240,
                      gridColumn: 'span 2',
                      height: 'calc(100vh - 60px - 115px - 40px - 20px)',
                    },
                  })}>
                  <ChatSidebar />
                </Box>
                <Box
                  sx={(theme) => ({
                    [theme.breakpoints.down('md')]: {
                      gridColumn: 'span 12',
                      ...(!detailsPage?.type && {
                        display: 'none',
                      }),
                    },
                    [theme.breakpoints.up('sm')]: {
                      gridColumn: 'span 9',
                    },
                  })}>
                  <Container maxWidth="lg">
                    <Box
                      sx={(theme) => ({
                        [theme.breakpoints.down('md')]: {
                          height: 'calc(100vh - 60px - 10px - 49px)',
                        },
                        [theme.breakpoints.up('sm')]: {
                          p: 1.7,
                          height: 'calc(100vh - 60px - 115px - 40px - 49px)',
                        },
                      })}>
                      {getDetailsReports()}
                    </Box>
                  </Container>
                </Box>
              </Grid>
            </>
          ) : (
            <ChatFeatureIsDisabled />
          )}
        </Box>
      </ThemeProvider>
    </MyPortfolioLayout>
  );
};

export default ChatAndCommunicationWrapper;
