import { useContext, useRef } from 'react';
import { useRouter } from 'next/router';
import { SearchBar } from '@ascd/witsby-components';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Box, IconButton } from '@mui/material';
import { ChatContext, eChatActionType } from '@contexts/chatContext';
import { ConversationList } from '../ConversationList';

const ChatSidebar = () => {
  const {
    dispatch,
    state: { globalChatSearch, detailsPage },
  } = useContext(ChatContext);
  const router = useRouter();
  const sideBarRef = useRef<HTMLDivElement>(null);

  const isPopUpChat = router.pathname !== '/chat-and-communication';

  return (
    <Box
      ref={sideBarRef}
      sx={{
        height: '100%',
        ...(!isPopUpChat && {
          p: 1.5,
        }),
      }}>
      {/* Search and Settings */}
      <Box
        sx={(theme) => ({
          width: isPopUpChat ? 'auto' : '100%',
          display: 'flex',
          alignItems: 'center',
          ...(isPopUpChat && {
            p: 1.5,
            borderBottomStyle: 'solid',
            borderWidth: theme.border.width[2],
            borderColor: theme.palette.grey[500],
          }),
        })}>
        {/* Search */}
        <Box
          sx={(theme) => ({
            width: '100%',
            '& .MuiFormControl-root.MuiTextField-root': {
              mt: 0,
              maxWidth: '100%',
              '.MuiInputBase-root': {
                height: '35px !important',
                flexDirection: 'row-reverse',
              },
              '.MuiOutlinedInput-input': {
                p: 0,
              },
              '.MuiSvgIcon-root': {
                fontSize: theme.font.size.LARGE,
              },
              fieldset: {
                borderRadius: theme.border.radius.REGULAR,
              },
            },
          })}>
          <SearchBar
            placeholder="Search..."
            value={globalChatSearch || ''}
            sx={{ mt: '10px', boxSizing: 'border-box' }}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              dispatch({
                data: e.target.value,
                type: 'GLOBAL_CHAT_SEARCH' as eChatActionType.GLOBAL_CHAT_SEARCH,
              });
            }}
          />
        </Box>
        {/* Settings */}
        <IconButton
          className="chat-settings"
          aria-label="Chat Settings"
          onClick={() => {
            dispatch({
              data: {
                ...detailsPage,
                type: 'CHAT_SETTINGS',
              },
              type: 'DETAILS_PAGE' as eChatActionType.DETAILS_PAGE,
            });
          }}
          sx={{ display: 'flex', alignItems: 'center' }}>
          <SettingsOutlinedIcon className="chat-settings" />
        </IconButton>
      </Box>
      {/* ConversationList */}
      <Box
        sx={{
          overflow: 'scroll',
          height: 'calc(100% - 44px)',
          ...(isPopUpChat && {
            height: 'calc(100% - 67px)',
          }),
        }}>
        <ConversationList />
      </Box>
    </Box>
  );
};

export default ChatSidebar;
