import { useCallback, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { IAttachments, SvgIcon } from '@ascd/witsby-components';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Box, BoxProps, Skeleton } from '@mui/material';
import map from 'lodash/map';
import GET_IMAGE_URL from '@graphql/schema/getImageUrl.graphql';
import { sleep } from '@utils';

interface IChatAttachment extends BoxProps {
  attachments: IAttachments[];
  onImageLoaded?: () => void;
}

const ChatAttachment = ({
  attachments,
  onImageLoaded = () => null,
  ...restProps
}: IChatAttachment): JSX.Element => {
  const apolloClient = useApolloClient();
  const [loaded, setIsLoaded] = useState<{ [key: string]: boolean }>({});
  const [fetchedUrl, setFetchedUrl] = useState<{ [key: string]: string }>({});

  const getFileUrl = useCallback(
    async (imageName: string, id: string, forceFully?: boolean) => {
      if (!imageName)
        setIsLoaded((prev) => ({
          ...prev,
          [`${id}`]: true,
        }));

      const MINUTE_MS = 60000;
      const defaultCacheImages = localStorage.getItem('images');
      let localStorageImages = defaultCacheImages ? JSON.parse(defaultCacheImages) : {};
      if (localStorageImages[`${imageName}`] && !forceFully) {
        setFetchedUrl((prev) => ({
          ...prev,
          [`${id}`]: localStorageImages[`${imageName}`].image,
        }));
        setIsLoaded((prev) => ({
          ...prev,
          [`${id}`]: true,
        }));
        return;
      }

      const response = await apolloClient.query({
        query: GET_IMAGE_URL,
        variables: { imageName },
        fetchPolicy: 'network-only',
      });

      const image = response?.data?.getImageUrl?.url || '';
      if (image) {
        setFetchedUrl((prev) => ({
          ...prev,
          [`${id}`]: image,
        }));

        const currentTime = new Date();
        localStorageImages = {
          ...localStorageImages,
          [imageName]: {
            image,
            expiry: currentTime.getTime() + MINUTE_MS * 14,
          },
        };
        localStorage.setItem('images', JSON.stringify(localStorageImages));
      }
      setIsLoaded((prev) => ({
        ...prev,
        [`${id}`]: true,
      }));
    },
    [apolloClient],
  );

  useEffect(() => {
    attachments.forEach((attachment) => {
      getFileUrl(attachment?.imageKey, attachment.id);
    });
  }, [getFileUrl, attachments]);

  const renderAttachmentPreview = (attachment: IAttachments) => {
    const { id, fileType, title } = attachment;
    const src = fetchedUrl[`${id}`];

    if (fileType.startsWith('image/') && fileType !== 'image/svg+xml') {
      return (
        <img
          key={id}
          src={src}
          loading="lazy"
          alt={title || 'Chat attachment'}
          title={title || 'Chat attachment'}
          onError={() => {
            setIsLoaded((prev) => ({
              ...prev,
              [`${id}`]: false,
            }));
            getFileUrl(attachment?.imageKey, attachment.id, true);
          }}
          onLoad={async () => {
            await sleep(500);
            setIsLoaded((prev) => ({
              ...prev,
              [`${id}`]: true,
            }));
            onImageLoaded();
          }}
        />
      );
    }

    return (
      <Box>
        <SvgIcon
          icon="FILE_DOWNLOAD"
          {...(fileType === 'application/pdf' && {
            icon: 'PDF_ATTACHMENT',
            viewBox: '0 0 50 64',
          })}
          {...(fileType.startsWith('video/') && {
            icon: 'VIDEO',
          })}
          {...(fileType.startsWith('audio/') && {
            icon: 'AUDIO',
          })}
          sx={(theme) => ({
            mb: 0.5,
            fontSize: theme.font.size.D1,
            fill: theme.palette.grey[200],
            color: theme.palette.grey[200],
            '&:focus': { outline: 'none' },
            '&:focus-visible': {
              outline: 'none',
              borderRadius: 10,
              background: theme.palette.grey[500],
            },
          })}
        />
        <Box sx={(theme) => ({ fontSize: theme.font.size.X_SMALL, lineHeight: 0.5 })}>{title}</Box>
      </Box>
    );
  };

  const renderAttachment = (attachment: IAttachments) => {
    if (!attachment) return null;

    return (
      <Box
        sx={{
          position: 'relative',
          img: {
            height: '100%',
            maxWidth: '100%',
            maxHeight: '200px',
            objectFit: 'contain',
          },
          '&:hover': {
            '.download-div': {
              display: 'flex',
            },
          },
        }}>
        <Box
          className="download-div"
          onClick={() => {
            window.open(fetchedUrl[`${attachment.id}`], '_blank');
          }}
          sx={(theme) => ({
            px: 0.7,
            top: 5,
            py: 0.5,
            right: 5,
            display: 'none',
            cursor: 'pointer',
            position: 'absolute',
            background: theme.palette.grey[100],
            borderRadius: theme.border.radius.SMALL,
            svg: {
              fontSize: theme.font.size.REGULAR,
              color: theme.palette.white[100],
            },
          })}>
          <CloudDownloadIcon />
        </Box>
        {renderAttachmentPreview(attachment)}
      </Box>
    );
  };

  return (
    <Box {...restProps}>
      {map(attachments || [], (attachment) => (
        <Box key={attachment?.id} mb={0.5}>
          {!loaded[attachment?.id] && <Skeleton variant="rounded" width={210} height={200} />}
          {loaded[attachment?.id] && renderAttachment(attachment)}
        </Box>
      ))}
    </Box>
  );
};

export default ChatAttachment;
