import { KeyboardEvent, lazy, Suspense, useEffect, useMemo, useRef } from 'react';
import { Box } from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import Quill, { ReactQuillProps } from 'react-quill';

const ReactQuill = lazy(() => import('react-quill'));

interface IChatQuill extends ReactQuillProps {
  id?: string;
  onFocus?: () => void;
  theme?: 'snow' | 'bubble';
  onChange: (value: string) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLDivElement>) => void;
  activeConversationId?: string;
  disabled?: boolean;
}

interface Range {
  index: number;
  length: number;
}

interface Context {
  [key: string]: string;
}

const ChatQuill = ({
  id = '',
  disabled,
  onChange,
  defaultValue,
  theme = 'snow',
  onFocus = () => null,
  onKeyDown = () => null,
  activeConversationId,
  ...restProps
}: IChatQuill) => {
  const quillRef = useRef<Quill | null>(null);

  useEffect(() => {
    if (quillRef.current && activeConversationId) {
      const editor = quillRef.current.getEditor();
      editor.focus();

      // Move the cursor to the end of the text
      const length = editor.getLength();
      editor.setSelection(length - 1, length - 1);
    }
  }, [activeConversationId]);

  const modules = useMemo(
    () => ({
      toolbar: [
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link'],
        ['clean'],
      ],
      clipboard: {
        matchVisual: false,
      },
      keyboard: {
        bindings: {
          // Prevent newline on Enter key press
          enter: {
            key: 13,
            handler: (_: Range, context: Context) => {
              if (!context.shiftKey) {
                return false; // Prevent default behavior
              }
              return true;
            },
          },
        },
      },
    }),
    [],
  );

  const formats = useMemo(
    () => [
      'font',
      'size',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'link',
      'list',
      'bullet',
      'indent',
      // 'image',
      'emoji',
      'voiceRecorder',
    ],
    [],
  );

  return (
    <Box
      sx={(muiTheme) => ({
        '& .ql-tooltip': { zIndex: 9999 },
        '& .ql-editor': { overflow: 'visible' },
        '& .quill .ql-container, & .quill .ql-container p': {
          fontFamily: [muiTheme.font.family.PRIMARY].join(','),
        },
        '& .ql-tooltip-editor input': {
          left: 0,
          textAlign: 'left',
        },
        '& a, & .ql-container.ql-bubble:not(.ql-disabled) a': {
          width: '100%',
          wordWrap: 'break-word',
          whiteSpace: 'break-spaces',
        },
      })}>
      <Suspense fallback={<div>Loading...</div>}>
        <ReactQuill
          id={id}
          theme={theme}
          ref={quillRef}
          readOnly={disabled}
          onKeyDown={onKeyDown}
          onFocus={() => onFocus()}
          defaultValue={defaultValue}
          modules={modules}
          formats={formats}
          placeholder="Type your message..."
          onChange={(updatedValue, _, source) => {
            if (source === 'user') {
              const value = updatedValue
                .replaceAll(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;') // replace tab with &nbsp;
                .replaceAll(' ', '&nbsp;'); // replace blank space with &nbsp;
              onChange(value);
            }
          }}
          {...restProps}
        />
      </Suspense>
    </Box>
  );
};

export default ChatQuill;
